import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { register } from "../../services/userService";
import { loginWithJWT, getCurrentUser } from "../../services/authService";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { getCountries } from "../../services/shippingService";

import "react-toastify/dist/ReactToastify.css";

class Register extends Form {
	state = {
		data: {
			username: "",
			name: "",
			countryID: "",
			password: "",
			confpassword: "",
		},
		errors: {},
		country: [],
	};

	async componentDidMount() {
		const { data: country } = await getCountries();

		this.setState({ country });
	}

	schema = {
		username: Joi.string().required().email().label("Email"),
		name: Joi.string().required().label("Name"),
		countryID: Joi.string().required().label("Country"),
		password: Joi.string().required().label("Password").min(5),
		confpassword: Joi.string()
			.label("Confirm Password")
			.required()
			.valid(Joi.ref("password"))
			.options({ language: { any: { allowOnly: "يجب ان يطابق كلمة السر" } } }),
	};

	doSubmit = async () => {
		//Call the server
		try {
			const { data: token } = await register(this.state.data);
			loginWithJWT(token.token);
			window.location = "/ar";
		} catch (ex) {
			if (ex.response && ex.response.status === 404) {
				const errors = { ...this.state.errors };
				errors.username = "البريد له حساب في سليم";
				this.setState({ errors });
			}
		}
	};

	render() {
		if (getCurrentUser()) return <Redirect to="/login" />;

		return (
			<React.Fragment>
				<div className="otherPage">
					<h1 className="prod ar">التسجيل</h1>
					<form className="register-login-form" onSubmit={this.handleSubmit}>
						{this.renderInput("username", "البريد الالكتروني")}
						<small id="emailHelp" className="form-text text-muted">
							لن نشارك بريدك في أي مكان اخر
						</small>
						{this.renderInput("name", "الاسم")}
						{this.renderSelect("countryID", "الدولة", this.state.country)}
						{this.renderInput("password", "كلمة السر", "password")}
						{this.renderInput("confpassword", "تأكيد كلمة السر", "password")}
						{this.renderButton("تسجيل", "login-btn")}
					</form>
				</div>
			</React.Fragment>
		);
	}
}

export default Register;
