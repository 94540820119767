import React, { Component } from "react";
import { getProduct } from "../../services/ProductService";
import carticon from "../icons/10.png";
import whatsapp from "../icons/2601a5d6a1661d0e7d453c7bb199a0cb-removebg-preview.png";
import { ToastContainer } from "react-toastify";

class ProductFormAr extends Component {
	state = {
		data: {
			_id: "",
			name: "",
			Arname: "",
			status: "",
			price: "",
			description: "",
			Ardescription: "",
			preview: "",
			photos: "",
		},
	};

	async componentDidMount() {
		const productID = this.props.match.params.id;
		try {
			const { data: product } = await getProduct(productID);
			this.setState({ data: this.mapToViewModel(product) });
		} catch (ex) {
			if (ex.response && ex.response.status === 404) {
				this.props.history.replace("/not-found");
			}
		}
	}

	handleImageClick = (psrc) => {
		let bigpic = document.querySelector(".grid-prev");
		bigpic.setAttribute("src", psrc);
		console.log(psrc);
	};

	handleWhatsAppQuery = (name) => {
		// href="https://wa.me/+972592171632/?text=Wassup"
		let link =
			"https://wa.me/+972592171632/?text=" +
			"مرحباً, أود الاستفسار عن ال" +
			name +
			"\n https://saleem-ps.com/ar/products/" +
			this.state.data._id;
		window.open(link);
	};

	mapToViewModel(product) {
		return {
			_id: product._id,
			name: product.name,
			Arname: product.Arname,
			status: product.status,
			price: product.price,
			description: product.description,
			Ardescription: product.Ardescription,
			preview: product.preview,
			photos: product.photos,
		};
	}
	render() {
		// console.log(this.state.data);
		const Pics = this.state.data.photos;
		console.log(this.state.data);
		return (
			<React.Fragment>
				<h1 className="Titlear">{this.state.data.Arname}</h1>
				<ToastContainer />
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="WRAP">
								<img
									className="grid-prev"
									src={this.state.data.preview}
									alt=""
								/>
							</div>
							<div className="images-grid">
								{Array.from(Pics).map((photosource) => (
									<img
										key={photosource}
										className="grid-image"
										src={photosource}
										alt="not found"
										onClick={() => this.handleImageClick(photosource)}
									/>
								))}
							</div>
						</div>
						<div className="col">
							<h2>{this.state.data.price} شيكل</h2>
							{this.state.data.status === "In Stock" && (
								<h6 className="green">متوفر</h6>
							)}
							{this.state.data.status === "Out of Stock" && (
								<h6 className="red">{this.state.data.status}</h6>
							)}
							<p className="ArDesc">{this.state.data.Ardescription}</p>

							{this.state.data.status === "Out of Stock" && (
								<button
									// onClick={() => this.props.handleAddtoCart(this.state.data)}
									className="liluzi"
									disabled
								>
									<span className="spanny">
										<img className="carti" src={carticon} alt="" />
									</span>
									أضف الى الحقيبة
								</button>
							)}
							{this.state.data.status === "In Stock" && (
								<button
									onClick={() => this.props.handleAddtoCart(this.state.data)}
									className="playboicarti"
								>
									<span className="spanny">
										<img className="carti" src={carticon} alt="" />
									</span>
									أضف الى الحقيبة
								</button>
							)}

							<button
								onClick={() => this.handleWhatsAppQuery(this.state.data.Arname)}
								className="whatsapp"
							>
								<span className="spanny">
									<img className="carti whats" src={whatsapp} alt="" />
								</span>
								التواصل على الواتساب{" "}
							</button>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ProductFormAr;
