import React from "react";
import Form from "../common/form";
import Joi from "joi-browser";
import { Redirect } from "react-router-dom";
import { getCountries } from "../../services/shippingService";
import { getCurrentUser } from "../../services/authService";

class Shipping extends Form {
	state = {
		data: { fullname: "", address: "", countryID: "", city: "", phone: "" },
		country: [],
		errors: {},
	};

	async componentDidMount() {
		const { data: country } = await getCountries();
		if (localStorage.getItem("shippingAddress")) {
			const shippingData = JSON.parse(localStorage.getItem("shippingAddress"));
			this.setState({ data: this.mapToViewModel(shippingData) });
		}
		this.setState({ country });
	}

	schema = {
		fullname: Joi.string().required().label("Full Name"),
		address: Joi.string().required().label("Address"),
		countryID: Joi.string().required().label("Country"),
		city: Joi.string().required().label("City"),
		phone: Joi.number().required().label("Phone Number").min(12),
	};

	mapToViewModel(shipping) {
		return {
			fullname: shipping.fullname,
			address: shipping.address,
			countryID: shipping.countryID,
			city: shipping.city,
			phone: shipping.phone,
		};
	}

	doSubmit = async () => {
		//Call the server
		try {
			// const response = await shipping(this.state.data);
			const shipping = this.state.data;
			localStorage.setItem("shippingAddress", JSON.stringify({ ...shipping }));
			window.location = "/ar/placeOrder";
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.username = ex.response.data;
				this.setState({ errors });
			}
		}
	};

	render() {
		if (!getCurrentUser()) window.location = "/login";
		if (this.props.cartNumber < 1) {
			window.location = "/cart";
			return;
		}
		return (
			<React.Fragment>
				<h1 className="prod ar">عنوان الشحن</h1>
				<form className="register-login-form" onSubmit={this.handleSubmit}>
					{this.renderInput(
						"fullname",
						"الاسم الكامل",
						"text",
						this.state.data.fullname
					)}
					{this.renderInput(
						"address",
						"العنوان",
						"text",
						this.state.data.address
					)}
					{this.renderSelect("countryID", "الدولة", this.state.country)}
					{this.renderInput("city", "المدينة", "text", this.state.data.city)}
					{this.renderInput(
						"phone",
						"رقم الهاتف",
						"text",
						this.state.data.phone
					)}
					<small className="ara">
						يرجى تضمين امتداد رمز البلد. +970 (فلسطين)، +972 (فلسطين 1948)
					</small>
					{this.renderButton("المتابعة لتقديم الطلب", "checkout")}
				</form>
			</React.Fragment>
		);
	}
}

export default Shipping;
