import React, { Component } from "react";
// import logo from "./images/Screenshot_2022-09-21_162742-removebg-preview.png";
import logo from "./images/logoo1.webp";

class Foote extends Component {
	render() {
		const handleLogoClick = () => {
			window.location = "/";
		};
		return (
			<React.Fragment>
				<footer className="footer-bs">
					<div className="row">
						<div className="col-md-4 footer-brand">
							<h2
								onClick={() => {
									handleLogoClick();
								}}
							>
								<img id="footerLogo" src={logo} alt="" />
							</h2>

							<p className="saleemrights">
								© 2022 Saleem Palestine, All rights reserved.
							</p>
							<div className="credit">
								<a target="_blank" href="https://01hman.com" className="othman">
									Othman Ali
								</a>
							</div>
						</div>

						<div className="col-md-2 footer-nav">
							<h3 className="gimmehead">Menu</h3>
							<div className="col-md-6">
								<ul className="pages">
									<li>
										<a href="/products">Products</a>
									</li>
									<li>
										<a href="/about">About</a>
									</li>
									<li>
										<a href="/contact">Contact</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-2 footer-social">
							<h3 className="gimmehead">Follow Us</h3>
							<ul>
								<li>
									<a
										target="_blank"
										href="https://www.facebook.com/saleem.pal1"
									>
										Facebook
									</a>
								</li>
								<li>
									<a target="_blank" href="https://wa.me/+972592171632">
										Whatsapp
									</a>
								</li>
								<li>
									<a
										target="_blank"
										href="https://www.instagram.com/saleem.ps1/"
									>
										Instagram
									</a>
								</li>
							</ul>
						</div>
						<div className="col-md-3 footer-ns">
							<h3 className="gimmehead">Newsletter</h3>
							<p>
								Subscribe to Saleem's newsletter for the latest updates on our
								sales and products.
							</p>
							<div className="input-group">
								<input
									type="text"
									className="form-control"
									placeholder="Your email..."
								/>
								<span className="input-group-btn">
									<button className="btn btn-danger" type="button">
										Subscribe
										<span className="glyphicon glyphicon-envelope"></span>
									</button>
								</span>
							</div>
						</div>
					</div>
				</footer>
			</React.Fragment>
		);
	}
}

export default Foote;
