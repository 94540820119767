import React from "react";

const Categories = (props) => {
	const { textProperty, valueProperty, selectedItem } = props;

	return (
		<ul className="list">
			{props.items.map((cat) => (
				<li
					key={cat[valueProperty]}
					className={
						JSON.stringify(cat) === JSON.stringify(selectedItem)
							? "list-items active"
							: "list-items"
					}
					onClick={() => props.onItemSelect(cat)}
				>
					{cat[textProperty]}
				</li>
			))}
		</ul>
	);
};

export default Categories;
