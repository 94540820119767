import React from "react";

const Input = ({ name, label, credentials, error, ...rest }) => {
	return (
		<div className="form-group">
			<label className="lbl" htmlFor={name}>
				{label}
			</label>
			<input
				{...rest}
				name={name}
				value={credentials}
				id={name}
				className="form-control"
			/>
			{error && <div className="alert alert-danger">{error}</div>}
		</div>
	);
};

export default Input;
