import httpservers from "./httpservers";
import jwtDecode from "jwt-decode";

httpservers.setJWT(getJWT());

export async function login(email, password) {
	const { data: token } = await httpservers.post(
		"https://salbackendapi-1974-i020.onrender.com/api/users/login",
		{
			email,
			password,
		}
	);
	localStorage.setItem("token", token.token);
}

export function getJWT() {
	return localStorage.getItem("token");
}

export function loginWithJWT(jwt) {
	localStorage.setItem("token", jwt);
}
export function logout() {
	localStorage.clear();
}

export function getCurrentUser() {
	try {
		const jwt = localStorage.getItem("token");
		return jwtDecode(jwt);
	} catch (ex) {
		return null;
	}
}
