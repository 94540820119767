import React, { Component } from "react";
import image from "../images/COVER.webp";

class ContactAr extends Component {
	state = {};
	render() {
		return (
			<React.Fragment>
				<h1 className="prod ar">التواصل</h1>
				<div className="container contactpg">
					<div className="row">
						<div className="col">
							<h3 className="ara">
								لأسئلتك وطلباتك الخاصة، يرجى التواصل معنا باستخدام البريد
								الإلكتروني أو وسائل التواصل الاجتماعي
							</h3>
							<img className="contactpic" src={image} alt="" />
						</div>
						<div className="col">
							<form className="form">
								<h5 className="prod ar">املئ معلوماتك التالية</h5>
								<label className="lbl" htmlFor="fname">
									الاسم الكامل
								</label>
								<input
									className="tarea ar"
									type="text"
									id="fname"
									name="fullname"
									placeholder="الاسم.."
								/>

								<label className="lbl" htmlFor="email">
									ايميل
								</label>
								<input
									className="tarea"
									type="email"
									id="email"
									name="email"
									placeholder="ايميل.."
								/>

								<label className="lbl" htmlFor="subject">
									ماذا تريد أن تسألنا؟
								</label>
								<textarea
									className="tarea ar"
									id="subject"
									name="subject"
									placeholder="اكتب شيئ ما.."
									style={{ height: 200 }}
								></textarea>

								<input
									className="submissiveandbreedable"
									type="submit"
									value="ارسال"
								/>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ContactAr;
