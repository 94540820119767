import React, { Component } from "react";

class My404Component extends Component {
	render() {
		return (
			<React.Fragment>
				<h1 className="prod">404 Error: Page Does not exist...</h1>
			</React.Fragment>
		);
	}
}

export default My404Component;
