import React from "react";
import Form from "./common/form";
import Joi from "joi-browser";
import { Redirect } from "react-router-dom";
import { getCountries } from "../services/shippingService";
import { getCurrentUser } from "../services/authService";

class Shipping extends Form {
	state = {
		data: { fullname: "", address: "", countryID: "", city: "", phone: "" },
		country: [],
		errors: {},
	};

	async componentDidMount() {
		const { data: country } = await getCountries();
		if (localStorage.getItem("shippingAddress")) {
			const shippingData = JSON.parse(localStorage.getItem("shippingAddress"));
			this.setState({ data: this.mapToViewModel(shippingData) });
		}
		this.setState({ country });
	}

	schema = {
		fullname: Joi.string().required().label("Full Name"),
		address: Joi.string().required().label("Address"),
		countryID: Joi.string().required().label("Country"),
		city: Joi.string().required().label("City"),
		phone: Joi.number().required().label("Phone Number").min(12),
	};

	mapToViewModel(shipping) {
		return {
			fullname: shipping.fullname,
			address: shipping.address,
			countryID: shipping.countryID,
			city: shipping.city,
			phone: shipping.phone,
		};
	}

	doSubmit = async () => {
		//Call the server
		try {
			// const response = await shipping(this.state.data);
			const shipping = this.state.data;
			localStorage.setItem("shippingAddress", JSON.stringify({ ...shipping }));
			window.location = "/placeOrder";
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.username = ex.response.data;
				this.setState({ errors });
			}
		}
	};

	render() {
		if (!getCurrentUser()) window.location = "/login";
		if (this.props.cartNumber < 1) {
			window.location = "/cart";
			return;
		}
		return (
			<React.Fragment>
				<h1 className="prod">Shipping Address</h1>
				<form className="register-login-form" onSubmit={this.handleSubmit}>
					{this.renderInput(
						"fullname",
						"Full Name",
						"text",
						this.state.data.fullname
					)}
					{this.renderInput(
						"address",
						"Address",
						"text",
						this.state.data.address
					)}
					{this.renderSelect("countryID", "Country", this.state.country)}
					{this.renderInput("city", "City", "text", this.state.data.city)}
					{this.renderInput(
						"phone",
						"Phone Number",
						"text",
						this.state.data.phone
					)}
					<small>
						Please include the country code extenstion. +970 (Palestine), +972
						(1948 Palestine)
					</small>
					{this.renderButton("Proceed to Place Order", "checkout")}
				</form>
			</React.Fragment>
		);
	}
}

export default Shipping;
