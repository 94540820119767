import React, { Component } from "react";
import image from "./images/COVER.webp";

class Contact extends Component {
	state = {};
	render() {
		return (
			<React.Fragment>
				<h1 className="prod">Contact</h1>
				<div className="container contactpg">
					<div className="row">
						<div className="col">
							<h3>
								For your questions and special orders please contact us using
								email or social media
							</h3>
							<img className="contactpic" src={image} alt="" />
						</div>
						<div className="col">
							<form className="form">
								<h5 className="prod">Fill your information below</h5>
								<label className="lbl" htmlFor="fname">
									Full Name
								</label>
								<input
									className="tarea"
									type="text"
									id="fname"
									name="fullname"
									placeholder="Your name.."
								/>

								<label className="lbl" htmlFor="email">
									E-mail
								</label>
								<input
									className="tarea"
									type="email"
									id="email"
									name="email"
									placeholder="Your email.."
								/>

								<label className="lbl" htmlFor="subject">
									What do you want to ask us?
								</label>
								<textarea
									className="tarea"
									id="subject"
									name="subject"
									placeholder="Write something.."
									style={{ height: 200 }}
								></textarea>

								<input
									className="submissiveandbreedable"
									type="submit"
									value="Submit"
								/>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Contact;
