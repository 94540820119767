import "./App.css";
import React, { useState, useEffect } from "react";
import NavBar from "./components/navbar";
import Home from "./components/home";
import Products from "./components/products";
import About from "./components/about";
import Contact from "./components/contact";
import Navy from "./components/nav";
import Login from "./components/login-form";
import Logout from "./components/logout";
import Register from "./components/register-form";
import { Route, Switch, Redirect } from "react-router-dom";
import Foote from "./components/Footer";
import ProductForm from "./components/productform";
import Cart from "./components/cart";
import Shipping from "./components/shipping";
import Place from "./components/placeOrder";
import My404Component from "./components/My404Component";
import HomeAr from "./components/Arabic/home";
import NavyAr from "./components/Arabic/nav";
import ProductsAr from "./components/Arabic/products";
import ProductFormAr from "./components/Arabic/productform";
import AboutAr from "./components/Arabic/about";
import CartAr from "./components/Arabic/cart";
import ContactAr from "./components/Arabic/contact";
import LoginAr from "./components/Arabic/login-form";
import RegisterAr from "./components/Arabic/register-form";
import LogoutAr from "./components/Arabic/logout";
import ShippingAr from "./components/Arabic/shipping";
import PlaceAr from "./components/Arabic/placeOrder";
import FooteAr from "./components/Arabic/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	const [cartItems, setCartItems] = useState(
		localStorage.getItem("cartItems")
			? JSON.parse(localStorage.getItem("cartItems"))
			: []
	);
	useEffect(() => {
		localStorage.setItem("cartItems", JSON.stringify(cartItems));
	}, [cartItems]);

	const handleAddtoCart = (product) => {
		const ProductExists = cartItems.find((item) => item._id === product._id);

		if (!ProductExists) {
			if (handleCurrPage() === "AR") {
				toast.success("تم اضافة المنتج الى الحقيبة");
			} else {
				toast.success("Product Added to Shopping Bag!");
			}

			setCartItems([...cartItems, { ...product, quantity: 1 }]);
			localStorage.setItem("cartItems", JSON.stringify(cartItems));
		} else {
			if (handleCurrPage() === "EN") {
				toast.error("Product Already in Shopping Bag");
			} else if (handleCurrPage() === "AR") {
				toast.error("المنتج موجود في الحقيبة");
			}
		}
	};

	const handleProductRemove = (product) => {
		const ProductExists = cartItems.find((item) => item._id === product._id);

		if (ProductExists) {
			setCartItems(cartItems.filter((item) => item._id !== product._id));
			window.location.reload();
			localStorage.setItem("cartItems", JSON.stringify(cartItems));
		}
	};

	const handleLanguageToggle = () => {
		if (window.location.href.includes("ar")) {
			window.location.href = "/";
			return true;
		} else {
			window.location = "/ar";
			return false;
		}
	};

	const handleCurrPage = () => {
		if (window.location.href.includes("/ar")) {
			return "AR";
		} else {
			return "EN";
		}
	};

	return (
		<React.Fragment>
			{handleCurrPage() === "EN" && (
				<Navy
					LanguageToggle={handleLanguageToggle}
					cartNumber={cartItems.length}
				/>
			)}
			{handleCurrPage() === "AR" && (
				<NavyAr
					LanguageToggle={handleLanguageToggle}
					cartNumber={cartItems.length}
				/>
			)}
			<Switch>
				<Route
					exact
					path="/products/:id"
					render={(props) => (
						<ProductForm {...props} handleAddtoCart={handleAddtoCart} />
					)}
				/>
				<Route
					exact
					path="/ar/products/:id"
					render={(props) => (
						<ProductFormAr {...props} handleAddtoCart={handleAddtoCart} />
					)}
				/>
				<Route exact path="/" component={Home} />
				<Route exact path="/ar" component={HomeAr} />

				<Route
					exact
					path="/cart"
					render={(props) => (
						<Cart
							{...props}
							handleAddtoCart={handleAddtoCart}
							cartItems={cartItems}
							handleProductRemove={handleProductRemove}
						/>
					)}
				/>

				<Route
					exact
					path="/ar/cart"
					render={(props) => (
						<CartAr
							{...props}
							handleAddtoCart={handleAddtoCart}
							cartItems={cartItems}
							handleProductRemove={handleProductRemove}
						/>
					)}
				/>

				<Route exact path="/products" component={Products} />
				<Route exact path="/ar/products" component={ProductsAr} />

				<Route
					exact
					path="/shipping"
					render={(props) => (
						<Shipping {...props} cartNumber={cartItems.length} />
					)}
				/>

				<Route
					exact
					path="/ar/shipping"
					render={(props) => (
						<ShippingAr {...props} cartNumber={cartItems.length} />
					)}
				/>

				<Route
					exact
					path="/placeOrder"
					render={(props) => <Place {...props} cartNumber={cartItems.length} />}
				/>

				<Route
					exact
					path="/ar/placeOrder"
					render={(props) => (
						<PlaceAr {...props} cartNumber={cartItems.length} />
					)}
				/>

				<Route exact path="/logout" component={Logout} />
				<Route exact path="/ar/logout" component={LogoutAr} />

				<Route exact path="/login" component={Login} />
				<Route exact path="/ar/login" component={LoginAr} />

				<Route exact path="/register" component={Register} />
				<Route exact path="/ar/register" component={RegisterAr} />

				<Route exact path="/about" component={About} />
				<Route exact path="/ar/about" component={AboutAr} />

				<Route exact path="/contact" component={Contact} />
				<Route exact path="/ar/contact" component={ContactAr} />

				<Route path="*" exact={true} component={My404Component} />
			</Switch>

			{handleCurrPage() === "EN" && <Foote />}
			{handleCurrPage() === "AR" && <FooteAr />}
			<script src="../js/navbar.js"></script>
		</React.Fragment>
	);
}

export default App;
