import React, { Component } from "react";
import image from "./images/Screenshot_2022-09-19_120525-removebg-preview.png";
import pali from "./icons/197467-removebg-preview-_1_.webp";
import hand from "./icons/Screenshot_2022-09-22_213006-removebg-preview.webp";
import quality from "./icons/2818753-removebg-preview.webp";
import hoppoe from "./icons/2164633-200-removebg-preview.webp";
import { queryByLabelText } from "@testing-library/react";

class Home extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="back">
					<div className="content">
						{" "}
						<div className="shop">
							{/* <div class="line-5 mid"></div> */}
							<p className="quote">سليم الروح جميل الثوب</p>
						</div>
					</div>
				</div>
				{/* <div class="line-3 homie"></div> */}

				<div className="bubble-grid">
					<div className="bubble-card">
						<div className="bubble">
							<img className="bubble-icon filterr" src={hand} alt="" />
						</div>
						<h4>Handmade</h4>
					</div>

					{/* <div className="vl"></div> */}

					<div className="bubble-card">
						<div className="bubble">
							<img className="bubble-icon palii" src={pali} alt="" />
						</div>
						<h4>Palestinian</h4>
					</div>

					{/* <div className="vl"></div> */}

					<div className="bubble-card">
						<div className="bubble">
							<img className="bubble-icon filterr" src={quality} alt="" />
						</div>
						<h4>Embroidered</h4>
					</div>

					{/* <div className="vl"></div> */}

					<div className="bubble-card">
						<div className="bubble">
							<img className="bubble-icon filterrr" src={hoppoe} alt="" />
						</div>
						<h4>Hoopoe Set</h4>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Home;
