import httpservers from "./httpservers";

export function getProducts() {
	return httpservers.get(
		"https://salbackendapi-1974-i020.onrender.com/api/products"
	);
}

export const getCategories = async () => {
	let tempCat = [];
	const { data } = await httpservers.get(
		"https://salbackendapi-1974-i020.onrender.com/api/categories"
	);
	for (let i = 0; i < data.length; i++) {
		if (data[i].name === "Hoopoe Collection") {
			continue;
		}
		tempCat.push(data[i]);
	}

	return tempCat;
};

export function getProduct(id) {
	return httpservers.get(
		"https://salbackendapi-1974-i020.onrender.com/api/products/" + id
	);
}

export function saveMovie(movie) {
	if (movie._id) {
		const body = { ...movie };
		delete body._id;
		return httpservers.put(
			"http://localhost:3900/api/movies/" + movie._id,
			body
		);
	}

	return httpservers.post("http://localhost:3900/api/movies/", movie);
}

// export function saveMovie(movie) {
// 	let movieInDb = movies.find((m) => m._id === movie._id) || {};
// 	movieInDb.name = movie.name;
// 	movieInDb.genre = genresAPI.genres.find((g) => g._id === movie.genreId);
// 	movieInDb.numberInStock = movie.numberInStock;
// 	movieInDb.dailyRentalRate = movie.dailyRentalRate;

// 	if (!movieInDb._id) {
// 		movieInDb._id = Date.now();
// 		movies.push(movieInDb);
// 	}

// 	return movieInDb;
// }

// export function deleteMovie(id) {
// 	let movieInDb = movies.find((m) => m._id === id);
// 	movies.splice(movies.indexOf(movieInDb), 1);
// 	return movieInDb;
// }
