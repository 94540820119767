import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getCurrentUser, login } from "../../services/authService";
import { Redirect } from "react-router-dom";

class LoginForm extends Form {
	state = {
		data: { username: "", password: "" },
		errors: {},
	};

	schema = {
		username: Joi.string().required().label("Username"),
		password: Joi.string().required().label("Password"),
	};

	doSubmit = async () => {
		//Call the server
		try {
			await login(this.state.data.username, this.state.data.password);
			const { state } = this.props.location;
			window.location = state ? state.from.pathname : "/ar";
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.username = "Invalid Email or Password";
				this.setState({ errors });
			}
		}
	};

	render() {
		if (getCurrentUser()) return <Redirect to="/" />;
		return (
			<React.Fragment>
				<div className="otherPage">
					<h1 className="prod ar">تسجيل دخول</h1>
					<form className="register-login-form" onSubmit={this.handleSubmit}>
						{this.renderInput("username", "البريد الالكتروني")}
						{this.renderInput("password", "كلمة السر", "password")}
						<a className="pass" href="http://google.com">
							هل نسيت كلمة السر؟
						</a>
						{this.renderButton("تسجيل الدخول", "login-btn")}
						<div className="WRAP">
							<a href="/ar/register" className="Ragea ar">
								ليس لديك حساب؟ سجّل هنا!
							</a>
						</div>
					</form>
				</div>
			</React.Fragment>
		);
	}
}

export default LoginForm;
