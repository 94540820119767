import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "./images/Screenshot_2022-09-21_162742-removebg-preview.png";
import cart from "./icons/126142-removebg-preview.png";
import { getCurrentUser } from "../services/authService";

class Navy extends Component {
	state = {};
	componentDidMount() {
		const user = getCurrentUser();
		this.setState({ user });
	}

	handleClick = () => {
		var hamburger = document.getElementById("hamburger-icon");
		hamburger.classList.toggle("open");
	};

	render() {
		const { cartNumber, LanguageToggle } = this.props;

		return (
			<React.Fragment>
				<header>
					<Link className="navbar-brand" to="/">
						<img id="brand" src={logo} alt="" />
					</Link>
					<nav>
						<ul>
							<ul className="navItems">
								{this.state.user && this.state.user.isAdmin && (
									<span>
										<h4 className="mama">رضاكي يا أمي</h4>
									</span>
								)}
								<button onClick={() => LanguageToggle()} className="arabic">
									<h6>العربية</h6>
								</button>
								<li className="nav-item">
									<NavLink className="links" to="/products">
										Products
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="links" to="/about">
										About
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="links" to="/contact">
										Contact
									</NavLink>
								</li>
								{!this.state.user && (
									<React.Fragment>
										<li className="nav-item">
											<NavLink className="links" to="/login">
												Login
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink className="links" to="/register">
												Register
											</NavLink>
										</li>
									</React.Fragment>
								)}
								{this.state.user && (
									<React.Fragment>
										<li className="nav-item">
											<NavLink className="links" to="/profile">
												{this.state.user.name}
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink className="links" to="/logout">
												Logout
											</NavLink>
										</li>
									</React.Fragment>
								)}{" "}
								<span>
									<a href="/cart">
										<img src={cart} className="bag" />
										<span className="item-num">
											{cartNumber !== 0 && cartNumber}
										</span>
									</a>
								</span>
							</ul>
						</ul>
					</nav>

					<button onClick={() => LanguageToggle()} className="arabic ar">
						<h6>العربية</h6>
					</button>

					<div className="cartNmenu">
						<span className="mobileCart">
							<NavLink to="/cart">
								<img src={cart} className="bag" />
								<span className="item-num">
									{cartNumber !== 0 && cartNumber}
								</span>
							</NavLink>
						</span>

						<div id="hamburger-icon" onClick={() => this.handleClick()}>
							<div className="bar1"></div>
							<div className="bar2"></div>
							<div className="bar3"></div>
							<ul className="mobile-menu">
								<li>
									<Link className="mobLink" to="/">
										Home
									</Link>
								</li>
								<li>
									<Link className="mobLink" to="/products">
										Products
									</Link>
								</li>
								<li>
									<Link className="mobLink" to="/about">
										About
									</Link>
								</li>
								<li>
									<Link className="mobLink" to="/contact">
										Contact
									</Link>
								</li>
								{!this.state.user && (
									<React.Fragment>
										<li>
											<NavLink className="mobLink" to="/login">
												Login
											</NavLink>
										</li>
										<li>
											<NavLink className="mobLink" to="/register">
												Register
											</NavLink>
										</li>
									</React.Fragment>
								)}
								{this.state.user && (
									<React.Fragment>
										<li>
											<NavLink className="mobLink" to="/profile">
												{this.state.user.name}
											</NavLink>
										</li>
										<li>
											<NavLink className="mobLink" to="/logout">
												Logout
											</NavLink>
										</li>
									</React.Fragment>
								)}
							</ul>
						</div>
					</div>
				</header>
			</React.Fragment>
		);
	}
}

export default Navy;
