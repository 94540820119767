import React, { Component } from "react";
import random from "../images/maisa.webp";
import cover from "../images/20211213_172558.webp";
import cover1 from "../images/20211218_121715.webp";

class AboutAr extends Component {
	state = {};
	render() {
		const handleProductLink = () => {
			window.location = "/ar/products";
		};
		return (
			<React.Fragment>
				<div className="top">
					<div className="midparaback">
						<div className="midpara ar">
							<h1>من نحن؟</h1>
							<p>
								سليم هي ماركة ملابس تقليدية راقية من{" "}
								<span className="ramallah"> رام الله، فلسطين</span>. نقدم ملابس
								عملية وعصرية مزينة بفرح بلمسة فلسطينية. قطع سليم فريدة وحصرية,
								مصممة ومفصلة من قبل النساء الجميلات اللواتي يعشقن تراثهن
								وثقافتهن بشغف أصيل. يعد سليم بالتميز والإعجاب بقطعه المميزة.{" "}
								<br></br>
							</p>
							<button
								onClick={() => {
									handleProductLink();
								}}
								className="productbutton"
							>
								تصفح منتجاتنا
							</button>
						</div>

						<div className="salPic backpic">
							<img id="descpic1" src={cover} alt="" />
							<img id="descpic1" src={cover1} alt="" />
						</div>
						<h6 className="infoabslaeem">
							سليم في معارض الملابس التقليدية الأخيرة
						</h6>
					</div>
				</div>

				<div className="container ab">
					<div className="row abrow">
						<div className="col arr">
							<h1>عن المصممة</h1>
							<p>
								<span className="maisa">ميساء سليم</span> نشأت بشغف للأناقة
								والموضة منذ طفولتها حين لاحظت إعداد قطع فريدة من نوعها في منزلها
								وتصميمها و نفذتها والدتها في الكويت. انتقلت ميسا إلى الأردن حضور
								الجامعة ، وتخصص في الكيمياء ، ولكن شغفها ظلت الموضة قوية لأنها
								كانت ترسم تصميماتها للأزياء لأمها والخروج للتسوق مع الأم
								للاختيار أقمشة فاخرة وتحويل أفكارها إلى واقع. حياتها المهنية في
								انطلقت صناعة الأزياء في البداية في إحياء المطرزة فساتين ،
								وتحديدا في رام الله والبيرة حيث استقرت وطوّرت شغفها بالتعلم
								والتدريب فيها تصميم الأزياء.
								<br></br> ميساء سليم تؤمن بأن لباس المرأة رمز التي توحي بروحها
								وجمالها الذي هو مصدر مصدر إلهام لتصميماتها في سليم. تعاونت مع
								خبيرة أتقنت فن التطريز بكل سرور والذكاء يصنع الأنماط التقليدية
								لتتناسب مع العصر الحديث وتصميمات عملية راقية.<br></br> ميساء
								يهدف إلى إنشاء خط أزياء أنيق وعصري و في نفس الوقت العملي ،
								لتمكين المرأة من ارتداء المصمم قطع بفرح وفخر في جميع الأوقات.
								كما أنها تؤمن بذلك ستلهم تصميماتها النساء الأخريات للمساهمة في
								الحفاظ على تراثنا واستدامته بالحب
							</p>
						</div>
						<div className="col-6 backpic">
							<img id="descpic" src={random} alt="" />
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AboutAr;
