import React, { Component } from "react";
import { getCurrentUser } from "../../services/authService";
import httpservers from "../../services/httpservers";

class PlaceAr extends Component {
	state = {
		data: {
			fullname: "",
			address: "",
			city: "",
			countryID: "",
			phone: "",
		},
	};
	componentDidMount() {
		const Shipping = JSON.parse(localStorage.getItem("shippingAddress"));
		this.setState({ data: this.mapToViewModel(Shipping) });
	}

	mapToViewModel(shipping) {
		return {
			fullname: shipping.fullname,
			address: shipping.address,
			city: shipping.city,
			countryID: shipping.countryID,
			phone: shipping.phone,
		};
	}

	handleSubtotal = () => {
		const cartItems = JSON.parse(localStorage.getItem("cartItems"));
		return cartItems.reduce((price, item) => price + item.price, 0);
	};

	handleShipping = () => {
		const user = getCurrentUser();
		if (user.countryID == "Palestine") return 0;
		else if (user.countryID == "1948 Palestine") return 35;
	};

	handlePlaceOrder = () => {
		try {
			httpservers.post(
				"https://https://salbackendapi-1974.onrender.com/api/orders"
			);
			return;
		} catch (error) {
			console.log(error);
		}
	};

	render() {
		if (!getCurrentUser()) {
			window.location = "/ar/login";
			return;
		}
		if (localStorage.getItem("shippingAddress") === "") {
			window.location = "/ar/shipping";
			return;
		}

		return (
			<React.Fragment>
				<h1 className="prod">Place Order</h1>
				<div className="GRID">
					<div className="col">
						<table className="infoBox ar">
							<thead>
								<h3 style={{ textAlign: "right" }} className="prod ar">
									معلومات الشحن
								</h3>
							</thead>
							<tr>
								<td className="right">الاسم الكامل:</td>
								<td className="left">{this.state.data.fullname}</td>
							</tr>
							<tr>
								<td className="right">العنوان: </td>
								<td className="left">{this.state.data.address}</td>
							</tr>
							<tr>
								<td className="right">رقم الهاتف: </td>
								<td className="left">{this.state.data.phone}</td>
							</tr>
							<tr>
								<td className="right">الدولة والمدينة:</td>
								<td className="left">
									{this.state.data.countryID}, {this.state.data.city}
								</td>
							</tr>
						</table>
					</div>
					<div className="col">
						<table className="infoBox ar">
							<thead>
								<h3 style={{ textAlign: "right" }} className="prod ar">
									المجموع
								</h3>
							</thead>
							<tr>
								<td>السعر: </td>
								<td> {this.handleSubtotal()}₪</td>
							</tr>
							<tr>
								<td>تكلفة الشحن: </td>
								<td>{this.handleShipping()}₪</td>
							</tr>
							<tr>
								<td>المجموع الكامل: </td>
								<td>{this.handleSubtotal() + this.handleShipping()}₪</td>
							</tr>
							<div className="WRAP">
								<button
									onClick={() => this.handlePlaceOrder()}
									className="PlaceOrder"
								>
									Place Order
								</button>
							</div>
						</table>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default PlaceAr;
