import httpservers from "./httpservers";

export function register(user) {
	console.log(user);
	return httpservers.post(
		"https://salbackendapi-1974-i020.onrender.com/api/users/register",
		{
			email: user.username,
			password: user.password,
			name: user.name,
			countryID: user.countryID,
		}
	);
}
