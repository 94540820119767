import React, { Component } from "react";
import { getProducts, getCategories } from "../../services/ProductService";
import Pagination from "./pagination";
import { paginate } from "../../js/paginate";
import { Link } from "react-router-dom";
import CategoriesAr from "../CategoriesAr";
import loading from "../images/loading-78.webp";
import _ from "lodash";

class ProductsAr extends Component {
	state = {
		products: [],
		categories: [],
		pageSize: 6,
		currentPage: 1,
		selectedCategory: { id: "", name: "جميع الأزياء" },
	};

	async componentDidMount() {
		const categoriesArr = await getCategories();
		const categories = [{ id: "", name: "جميع الأزياء" }, ...categoriesArr];

		const { data: products } = await getProducts();
		this.setState({ products, categories });
	}

	handlePageChange = (page) => {
		this.setState({ currentPage: page });
	};

	handleItemLink = (src) => {
		return src;
	};

	handleCategorySelect = (category) => {
		this.setState({
			selectedCategory: category,
			currentPage: 1,
		});
	};

	getFeatured = () => {
		let products = this.state.products;
		var featured = [];
		for (let i = 0; i < products.length; i++) {
			if (products[i].name === "Palestinian Princess Set") {
				featured[0] = products[i];
			}
			if (products[i].name === "Hoopoe Wooden Handle Bag") {
				featured[1] = products[i];
			}
			if (products[i].name === "Femme Skirt and Shirt Set") {
				featured[2] = products[i];
			}
		}
		console.log(featured);
		return featured;
	};

	getPagedData = () => {
		const {
			pageSize,
			currentPage,
			selectedCategory,
			products: allProducts,
		} = this.state;

		let filtered = allProducts;
		if (selectedCategory && selectedCategory.id)
			filtered = allProducts.filter(
				(m) => m.category.id === selectedCategory.id
			);

		// const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

		const movies = paginate(filtered, currentPage, pageSize);

		return { totalCount: filtered.length, data: movies };
	};

	render() {
		// const Pagedproducts = paginate(
		// 	this.state.products,
		// 	this.state.currentPage,
		// 	this.state.pageSize
		// );

		const { totalCount, data: Pagedproducts } = this.getPagedData();
		const featured = this.getFeatured();

		return (
			<React.Fragment>
				<div className="product">
					<h3 className="cash ar">
						الدفع عند الاستلام متاح للطلبات داخل فلسطين{" "}
					</h3>

					{this.state.products.length > 2 &&
						this.state.categories.length > 2 && (
							<div className="Feature">
								<h1 className="prod ar">الأزياء المميزة</h1>
								<div className="cards col">
									{featured.map((item) => (
										<div id="card" key={item._id}>
											<div className="image">
												<Link
													className="MovieLink"
													to={`/ar/products/${item._id}`}
												>
													<img
														className="prev"
														src={this.handleItemLink(item.preview)}
														alt={"Image Not Available"}
													/>
												</Link>
												<span className="featuredSpan ar">جديد</span>
											</div>
											<h4 className="ItemName ar">{item.Arname}</h4>
										</div>
									))}
								</div>
							</div>
						)}

					{this.state.categories.length > 2 && (
						<div style={{ marginLeft: "25px" }} className="WRAP">
							<CategoriesAr
								items={this.state.categories}
								onItemSelect={this.handleCategorySelect}
								selectedItem={this.state.selectedCategory}
								textProperty="name"
								valueProperty="id"
							/>
						</div>
					)}

					{this.state.products.length < 2 && (
						<div className="paddy">
							<img className="loading" src={loading} />
						</div>
					)}

					{this.state.products.length > 2 && (
						<React.Fragment>
							<div className="cards col">
								{Pagedproducts.map((item) => (
									<div id="card" key={item._id}>
										<div className="image">
											<Link
												className="MovieLink"
												// info={
												// 	({ title: movie.title },
												// 	{ stock: movie.numberInStock },
												// 	{ genre: movie.genre.name },
												// 	{ rating: movie.dailyRentalRent })
												// }
												to={`/ar/products/${item._id}`}
											>
												<img
													className="prev"
													src={this.handleItemLink(item.preview)}
													alt={"Image Not Available"}
												/>
											</Link>
										</div>
										<h4 className="ItemName ar">
											{item.Arname}{" "}
											{/* {item.status === "Out of Stock" && (
											<span className="red">Out of Stock</span>
										)}{" "}
										{item.status === "In Stock" && (
											<span className="green">In Stock</span>
										)} */}
										</h4>
									</div>
								))}
							</div>
						</React.Fragment>
					)}
					{totalCount > 0 && (
						<Pagination
							itemsCount={totalCount}
							pageSize={this.state.pageSize}
							onPageChange={this.handlePageChange}
							currentPage={this.state.currentPage}
						/>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default ProductsAr;
