import React from "react";

const Categories = (props) => {
	const { textProperty, valueProperty, selectedItem } = props;

	const handleArabic = (str) => {
		if (str === "Dresses") {
			str = "فساتين";
		} else if (str === "Shirts") {
			str = "قمصان";
		} else if (str === "Accessories") {
			str = "اكسسوارات";
		} else if (str === "Hoopoe Collection") {
			str = "تشكيلة الهدهد";
		} else if (str === "Jackets") {
			str = "جاكيتات";
		} else if (str === "Sets") {
			str = "مجموعات";
		}
		return str;
	};
	return (
		<ul className="list ar">
			{props.items.map((cat) => (
				<li
					key={cat[valueProperty]}
					className={
						JSON.stringify(cat) === JSON.stringify(selectedItem)
							? "list-items active"
							: "list-items"
					}
					onClick={() => props.onItemSelect(cat)}
				>
					{handleArabic(cat[textProperty])}
				</li>
			))}
		</ul>
	);
};

export default Categories;
