import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "./images/Screenshot_2022-09-21_162742-removebg-preview.png";
import cart from "./icons/126142-removebg-preview.png";
import { getCurrentUser } from "../services/authService";
import "@pluginjs/collapse";

class NavBar extends Component {
	state = {};
	componentDidMount() {
		const user = getCurrentUser();
		this.setState({ user });
	}

	render() {
		const { cartNumber } = this.props;
		return (
			<nav id="navbar" className="navbar navbar-expand-lg sticky-top">
				<Link className="navbar-brand" to="/">
					<img id="brand" src={logo} alt="" />
				</Link>

				<button
					className="navbar-toggler"
					data-bs-toggle="collapse"
					data-bs-target="#navbar"
				>
					<span className="navbar-toggler-icon"></span>
				</button>

				<div className="navbar-collapse collapse" id="navbar">
					<ul className="navbar-nav">
						{this.state.user && this.state.user.isAdmin && (
							<span>
								<h4 className="mama">رضاكي يا أمي</h4>
							</span>
						)}
						<span className="arabic">
							<h6>ar</h6>
						</span>
						<li className="nav-item">
							<NavLink className="links" to="/products">
								Products
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="links" to="/about">
								About
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="links" to="/contact">
								Contact
							</NavLink>
						</li>
						{!this.state.user && (
							<React.Fragment>
								<li className="nav-item">
									<NavLink className="links" to="/login">
										Login
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="links" to="/register">
										Register
									</NavLink>
								</li>
							</React.Fragment>
						)}
						{this.state.user && (
							<React.Fragment>
								<li className="nav-item">
									<NavLink className="links" to="/profile">
										{this.state.user.name}
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="links" to="/logout">
										Logout
									</NavLink>
								</li>
							</React.Fragment>
						)}{" "}
						<li className="nav-item">
							<span>
								<NavLink to="/cart">
									<img src={cart} className="bag" />
									<span className="item-num">
										{cartNumber !== 0 && cartNumber}
									</span>
								</NavLink>
							</span>
						</li>
					</ul>
				</div>
			</nav>
		);
	}
}

export default NavBar;
