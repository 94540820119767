import React, { Component } from "react";
import remove from "./icons/57165-removebg-preview.png";
import none from "./images/CER.webp";
import { getCurrentUser } from "../services/authService";
import { getProduct } from "../services/ProductService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Cart extends Component {
	state = { cart: [] };

	componentDidMount = async () => {
		const cartLinkArr = JSON.parse(localStorage.getItem("cartItems"));
		const cart = [];
		for (let i = 0; i < cartLinkArr.length; i++) {
			const { data } = await getProduct(cartLinkArr[i]._id);
			cart.push(data);
		}
		this.setState({ cart });
	};

	render() {
		const { cartItems, handleProductRemove } = this.props;

		const handleProductLink = (id) => {
			return "https://saleem-ps.com/products/" + id;
		};
		const handleTotalPrice = () => {
			return cartItems.reduce((price, item) => price + item.price, 0);
		};
		const handleSubtotal = () => {
			return handleTotalPrice() + handleShipping();
		};
		const handleShipping = () => {
			if (!getCurrentUser()) {
				return 0;
			}
			const user = getCurrentUser();
			if (user.countryID === "Palestine") return 0;
			else if (user.countryID === "1948 Palestine") return 35;
		};

		const handleOutOfStockCheck = () => {
			this.state.cart.map((item) => {
				if (item.status === "Out of Stock") {
					handleProductRemove(item);
					setTimeout(notify(item.name), 500);
				}
			});
		};

		const handleCheckout = () => {
			if (getCurrentUser()) {
				window.location = "/shipping";
			} else {
				window.location = "/login";
			}
		};
		const notify = (name) => {
			toast.error(name + " is now Out of Stock!");
		};

		return (
			<React.Fragment>
				<div>
					{handleOutOfStockCheck()}
					<ToastContainer />
				</div>

				<h1 className="prod">Shopping bag</h1>
				<div className="container WRAP">
					{cartItems.length === 0 && (
						<div>
							<h2 style={{ textAlign: "center" }}>
								No products are added to the bag
							</h2>
							<img src={none} className="prev" alt="" />
						</div>
					)}
					{cartItems.length !== 0 && (
						<div>
							<table className="table">
								<thead className="head">
									<tr>
										<th scope="col">Item</th>
										<th scope="col">Name</th>
										<th scope="col">Price</th>
										<th scope="col">Status</th>
										<th scope="col"></th>
									</tr>
								</thead>

								{this.state.cart.map((item) => (
									<tbody>
										<tr>
											<th className="tiddie">
												<img
													className="cart-item-image"
													src={item.preview}
													alt={item.name}
												/>
											</th>
											<th className="tiddie">
												<a
													style={{ textDecoration: "none", color: "black" }}
													href={handleProductLink(item._id)}
												>
													<h4>{item.name}</h4>
												</a>
											</th>
											<td className="tiddie">
												<h2>{item.price}₪</h2>
											</td>
											<td className="tiddie">
												{item.status === "In Stock" && (
													<h4 className="green">In Stock</h4>
												)}
												{item.status === "Out of Stock" && (
													<h4 className="red">Not Available</h4>
												)}
											</td>
											<td className="tiddie">
												<span onClick={() => handleProductRemove(item)}>
													<img src={remove} className="remove" alt="X" />
												</span>
											</td>
										</tr>
									</tbody>
								))}
							</table>
						</div>
					)}
				</div>

				<div className="container">
					<div className="line-2"></div>
					{cartItems.length !== 0 && (
						<React.Fragment>
							<table className="Prices">
								<thead>
									<h3 style={{ marginLeft: "50px" }} className="prod">
										Checkout
									</h3>
								</thead>
								<tr>
									<td>Subtotal: </td>
									<td> {handleTotalPrice()}₪</td>
								</tr>
								<tr>
									<td>Shipping: </td>
									<td>{handleShipping()}₪</td>
								</tr>
								<tr>
									<td className="lite">
										0₪ shipping fees inside the west bank and 35₪ for 1948
										Palestine.
										<br></br>international shipping depends on the shipping
										company
									</td>
								</tr>
								<tr>
									<td>Subtotal: </td>
									<td>{handleSubtotal()}₪</td>
								</tr>
							</table>

							<div className="WRAP">
								<button onClick={() => handleCheckout()} className="checkout">
									Proceed to Shipping
								</button>
							</div>
						</React.Fragment>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default Cart;
