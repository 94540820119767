import React, { Component } from "react";
import random from "./images/maisa.webp";
import cover from "./images/20211213_172558.webp";
import cover1 from "./images/20211218_121715.webp";

class About extends Component {
	state = {};
	render() {
		const handleProductLink = () => {
			window.location = "/products";
		};
		return (
			<React.Fragment>
				<div className="top">
					<div className="midparaback">
						<div className="midpara">
							<h1>Who are we?</h1>
							<p>
								Saleem is a high-end traditional clothing brand from{" "}
								<span className="ramallah">Ramallah, Palestine</span>. We offer
								practical and modern designer clothes adorned with a joyous
								Palestinian touch. Saleems' pieces are unique and exclusive,
								woven by beautiful women who love their heritage and culture
								with a genuine passion. Saleem promises distinction for its
								customers and admiration for their designer piece. <br></br>
							</p>
							<button
								onClick={() => {
									handleProductLink();
								}}
								className="productbutton"
							>
								Check out our products
							</button>
						</div>

						<div className="salPic backpic">
							<img id="descpic1" src={cover} alt="" />
							<img id="descpic1" src={cover1} alt="" />
						</div>
						<h6 className="infoabslaeem">
							Saleem in recent traditional clothing conventions
						</h6>
					</div>
				</div>

				<div className="container ab">
					<div className="row abrow">
						<div className="col">
							<h1>About The Designer</h1>
							<p>
								<span className="maisa">Maisa Saleem</span> grew up with a
								passion for style and fashion since her childhood as she
								observed the preparation of unique pieces in her home, designed
								and implemented by her mother in Kuwait. Maisa moved to Jordan
								to attend univeristy, majoring in chemistry, yet her passion for
								fashion remained strong as she used to draw her fashion designs
								for her mother and go out shopping with the mother to choose
								fine fabrics and turn her ideas into real life. Her career in
								the fashion industry took off initially in reviving embroidered
								dresses, specifically in Ramallah and Al-Bireh, where she
								settled and developed her passion for learning and training in
								fashion design.
								<br></br> Maisa saleem believes that a women’s dress is a symbol
								that suggests her spirit and beauty, which is a source of
								inspiration for her designs in Saleem. She collaborated with
								expert women who mastered the art of embroidery with pleasure
								and intelligence crafting traditional patterns to suit modern
								and practical high-end designs.<br></br> Maisa saleem aims to
								create a fashion line that is elegant, modern and at the same
								time practical, to enable women to wear designer pieces with joy
								and pride at all times. She also believes that her designs will
								inspire other women, to contribute to the preservation and
								sustainability of the our heritage with love
							</p>
						</div>
						<div className="col backpic">
							<img id="descpic" src={random} alt="" />
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default About;
