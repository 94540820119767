import React, { Component } from "react";
import { getProducts, getCategories } from "../services/ProductService";
import Pagination from "./pagination";
import { paginate } from "../js/paginate";
import { Link } from "react-router-dom";
import Categories from "./Categories";
import loading from "./images/loading-78.webp";
import _ from "lodash";

class Products extends Component {
	state = {
		products: [],
		categories: [],
		pageSize: 6,
		currentPage: 1,
		selectedCategory: { id: "", name: "All Products" },
	};

	async componentDidMount() {
		const categoriesArr = await getCategories();
		console.log(categoriesArr);
		const categories = [{ id: "", name: "All Products" }, ...categoriesArr];

		const { data: products } = await getProducts();
		this.setState({ products, categories });
	}

	getFeatured = () => {
		let products = this.state.products;
		var featured = [];
		for (let i = 0; i < products.length; i++) {
			if (products[i].name === "Palestinian Princess Set") {
				featured[0] = products[i];
			}
			if (products[i].name === "Hoopoe Wooden Handle Bag") {
				featured[1] = products[i];
			}
			if (products[i].name === "Femme Skirt and Shirt Set") {
				featured[2] = products[i];
			}
		}
		console.log(featured);
		return featured;
	};

	handlePageChange = (page) => {
		this.setState({ currentPage: page });
	};

	handleItemLink = (src) => {
		return src;
	};

	handleCategorySelect = (category) => {
		this.setState({
			selectedCategory: category,
			currentPage: 1,
		});
	};

	getPagedData = () => {
		const {
			pageSize,
			currentPage,
			selectedCategory,
			products: allProducts,
		} = this.state;

		let filtered = allProducts;
		if (selectedCategory && selectedCategory.id)
			filtered = allProducts.filter(
				(m) => m.category.id === selectedCategory.id
			);

		// const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

		const movies = paginate(filtered, currentPage, pageSize);

		return { totalCount: filtered.length, data: movies };
	};

	render() {
		// const Pagedproducts = paginate(
		// 	this.state.products,
		// 	this.state.currentPage,
		// 	this.state.pageSize
		// );

		const { totalCount, data: Pagedproducts } = this.getPagedData();
		const featured = this.getFeatured();

		return (
			<React.Fragment>
				<div className="product">
					<h3 className="cash">
						Cash on delivery available for orders in Palestine
					</h3>

					{this.state.products.length > 2 &&
						this.state.categories.length > 2 && (
							<div className="Feature">
								<h1 className="prod">Featured Merchandise</h1>
								<div className="cards col">
									{featured.map((item) => (
										<div id="card" key={item._id}>
											<div className="image">
												<Link
													className="MovieLink"
													to={`/products/${item._id}`}
												>
													<img
														className="prev"
														src={this.handleItemLink(item.preview)}
														alt={"Image Not Available"}
													/>
												</Link>
												<span className="featuredSpan">New</span>
											</div>
											<h4 className="ItemName">{item.name}</h4>
										</div>
									))}
								</div>
							</div>
						)}

					{this.state.categories.length > 2 && (
						<div style={{ marginLeft: "25px" }} className="WRAP">
							<Categories
								items={this.state.categories}
								onItemSelect={this.handleCategorySelect}
								selectedItem={this.state.selectedCategory}
								textProperty="name"
								valueProperty="id"
							/>
						</div>
					)}

					{this.state.products.length < 2 && (
						<div className="paddy">
							<img className="loading" src={loading} />
						</div>
					)}

					{this.state.products.length > 2 && (
						<div className="cards col">
							{Pagedproducts.map((item) => (
								<div id="card" key={item._id}>
									<div className="image">
										<Link
											className="MovieLink"
											// info={
											// 	({ title: movie.title },
											// 	{ stock: movie.numberInStock },
											// 	{ genre: movie.genre.name },
											// 	{ rating: movie.dailyRentalRent })
											// }
											to={`/products/${item._id}`}
										>
											<img
												className="prev"
												src={this.handleItemLink(item.preview)}
												alt={"Image Not Available"}
											/>
										</Link>
									</div>
									<h4 className="ItemName">{item.name}</h4>
								</div>
							))}
						</div>
					)}
					{totalCount > 0 && (
						<Pagination
							itemsCount={totalCount}
							pageSize={this.state.pageSize}
							onPageChange={this.handlePageChange}
							currentPage={this.state.currentPage}
						/>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default Products;
