import React, { Component } from "react";
// import logo from "./images/Screenshot_2022-09-21_162742-removebg-preview.png";
import logo from "../images/logoo1.webp";

class FooteAr extends Component {
	render() {
		const handleLogoClick = () => {
			window.location = "/ar";
		};
		return (
			<React.Fragment>
				<footer className="footer-bs">
					<div className="row">
						<div className="col-md-4 footer-brand">
							<h2
								onClick={() => {
									handleLogoClick();
								}}
							>
								<img id="footerLogo" src={logo} alt="" />
							</h2>

							<p className="saleemrights">
								© 2022 سليم فلسطين ، جميع الحقوق محفوظة
							</p>
							<div className="credit">
								<a target="_blank" href="https://01hman.com" className="othman">
									Othman Ali
								</a>
							</div>
						</div>

						<div className="col-md-2 footer-nav ara">
							<h3 className="gimmehead">القائمة</h3>
							<div className="col-md-6">
								<ul className="pages">
									<li>
										<a href="/ar/products">الأزياء</a>
									</li>
									<li>
										<a href="/ar/about">من نحن</a>
									</li>
									<li>
										<a href="/ar/contact">التواصل</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-2 footer-social ara">
							<h3 className="gimmehead">تابعونا</h3>
							<ul>
								<li>
									<a
										target="_blank"
										href="https://www.facebook.com/saleem.pal1"
									>
										فيسبوك
									</a>
								</li>
								<li>
									<a target="_blank" href="https://wa.me/+972592171632">
										واتساب
									</a>
								</li>
								<li>
									<a
										target="_blank"
										href="https://www.instagram.com/saleem.ps1/"
									>
										انستاغرام
									</a>
								</li>
							</ul>
						</div>
						<div className="col-md-3 footer-ns ara">
							<h3 className="gimmehead">نشرتنا الاخبارية</h3>
							<p className="ara">
								اشترك في النشرة الإخبارية لسليم للحصول على آخر التحديثات حول
								مبيعاتنا ومنتجاتنا.
							</p>
							<div className="input-group">
								<input
									type="text"
									className="form-control"
									placeholder="بريدك الالكتروني..."
								/>
								<span className="input-group-btn">
									<button className="btn btn-danger" type="button">
										الاشتراك
										<span className="glyphicon glyphicon-envelope"></span>
									</button>
								</span>
							</div>
						</div>
					</div>
				</footer>
			</React.Fragment>
		);
	}
}

export default FooteAr;
